<template>
  <div class="agenda">
    <div class="agendItem">
      <el-dialog
        :title="title ? title : $t('meetingAppointment')"
        :visible="orderVisible"
        width="700px"
        class="videoPlayDialog"
        @close="orderVisibleChange()"
      >
        <el-form :label-width="LOCALE == 'en' ? '160px' : '90px'">
          <el-form-item prop="date" class="time">
            <template slot="label">
              <div class="labelSlot">{{ $t("appointmentData") }}</div>
            </template>
            <el-date-picker
              v-model="agendaForm.day"
              :picker-options="pickerOptions"
              type="date"
              size="small"
              :placeholder="$t('appointmentData')"
              @change="handleDay"
            ></el-date-picker>
          </el-form-item>

          <el-form-item prop="end_time" class="time">
            <template slot="label">
              <div class="labelSlot">{{ $t("appointmentTime") }}</div>
            </template>
            <el-time-select
              v-model="agendaForm.start_time"
              :picker-options="{
                start: '00:00',
                step: '00:10',
                end: '23:20',
                minTime: date,
              }"
              size="small"
              format="HH:mm"
              value-format="HH:mm"
              :placeholder="$t('beginTime')"
              @change="handleStartTime"
            >
            </el-time-select>
            <span>-</span>
            <el-time-select
              size="small"
              :placeholder="$t('endTime')"
              v-model="agendaForm.end_time"
              format="HH:mm"
              value-format="HH:mm"
              :picker-options="{
                start: '00:10',
                step: '00:10',
                end: '23:50',
                minTime: agendaForm.start_time ? agendaForm.start_time : date,
              }"
            >
            </el-time-select>
          </el-form-item>

          <el-form-item prop="interviewPosition" v-if="showjobPpositionList">
            <template slot="label">
              <div class="labelSlot">{{ $t("jobInterview") }}</div>
            </template>
            <el-select
              v-model="agendaForm.position_id"
              size="small"
              :placeholder="$t('pleaseSelect')"
              :clearable="true"
              style="width: 50%"
            >
              <el-option
                v-for="item in jobPpositionList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="send_postscript"
            :label="$t('notes')"
            v-if="
              PJSource !== 13 &&
              PJSource !== 12 &&
              PJSource !== 7 &&
              PJSource !== 26 &&
              PJSource !== 16 &&
              PJSource !== 14 &&
              PJSource !== 18 &&
              PJSource !== 27 &&
              PJSource !== 29 &&
              PJSource !== 30 &&
              PJSource !== 34
              
            "
          >
            <el-input
              type="textarea"
              :placeholder="$t('entercontent')"
              v-model="agendaForm.send_postscript"
              maxlength="200"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div></div>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="orderVisibleChange()" size="small">{{
            $t("cancel")
          }}</el-button>
          <el-button
            type="primary"
            size="small"
            @click="confimAgenda"
            :loading="btnLoading"
            >{{ $t("confirm") }}</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { secondFormat } from "~/basePlugins/filters";
export default {
  props: {
    orderVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    jobPpositionList: {
      type: Array,
      default: () => [],
    },
    showjobPpositionList: {
      typeof: Boolean,
      default: false,
    },
    
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      btnLoading: false,
      talkVisible: false,
      dialogTxt: "",
      agendaForm: {
        start_time: "",
        day: "",
        end_time: "",
        send_postscript: "",
        position_id: "",
      },
      pitchObj: {},
      date: "",
    };
  },
  mounted() {
  },
  watch: {
    orderVisible() {
      if (!this.orderVisible) {
        this.btnLoading = false;
        this.agendaForm = {
          start_time: "",
          day: "",
          end_time: "",
          send_postscript: "",
          position_id: "",
        };
      } else {
        this.date = secondFormat(moment(new Date()).unix(), "T");
      }
    },
  },
  methods: {
    hide() {},
    orderVisibleChange(val) {
      this.$emit("orderVisibleShow", val);
    },
    confimAgenda() {
      if (!this.agendaForm.day) {
        return false;
      }
      let startTime = null;
      let endTime = null;
      let nowTime = moment(new Date()).unix();
      let time = this.agendaForm.start_time;
      let hour = Number(time.split(":")[0]);
      let min = Number(time.split(":")[1]);
      let s = Number(hour * 3600) + Number(min * 60);
      startTime = (s + moment(this.agendaForm.day).unix()).valueOf();
      if (this.agendaForm.end_time) {
        let time = this.agendaForm.end_time;
        let hour = Number(time.split(":")[0]);
        let min = Number(time.split(":")[1]);
        let s = Number(hour * 3600) + Number(min * 60);
        endTime = (s + moment(this.agendaForm.day).unix()).valueOf();
      }
      let dayTime = moment(this.agendaForm.day).unix();
      let newTimeObj = {
        start_time: startTime ? startTime : "",
        end_time: endTime ? endTime : "",
        day: dayTime ? dayTime : "",
        send_postscript: this.agendaForm.send_postscript,
      };
      if (this.showjobPpositionList) {
        newTimeObj.position_id = this.agendaForm.position_id;
      }
      if (!newTimeObj.day) {
        this.$message.warning(this.$t("companyCatalog1v1Error4"));
        return;
      } else if (!newTimeObj.start_time) {
        this.$message.warning(this.$t("companyCatalog1v1Error5"));
        return;
      } else if (!newTimeObj.end_time) {
        this.$message.warning(this.$t("companyCatalog1v1Error6"));
        return;
      } else if (newTimeObj.start_time && newTimeObj.start_time < nowTime) {
        this.$message.warning(this.$t("companyCatalog1v1Error7"));
        return;
      } else if (newTimeObj.end_time - newTimeObj.start_time > 1800) {
        this.$message.warning(this.$t("companyCatalog1v1Error8"));
        return;
      } else if (
        newTimeObj.start_time &&
        newTimeObj.end_time &&
        newTimeObj.start_time >= newTimeObj.end_time
      ) {
        this.$message.warning(this.$t("companyCatalog1v1Error9"));
        return;
      } else if (this.showjobPpositionList && newTimeObj.position_id == "") {
        this.$message.warning(this.$t('pleaseEnterInterview'));
        return;
      } else {
        this.btnLoading = true;
        this.$emit("dataChange", newTimeObj);
      }
    },
    loadingChange() {
      this.btnLoading = false;
    },
    handleDay(val) {
      let date = secondFormat(moment(val).unix(), "LL");
      let current = secondFormat(moment(new Date()).unix(), "LL");
      if (date === current) {
        this.date = secondFormat(moment(new Date()).unix(), "T");
      } else {
        this.date = "";
      }
    },
    handleStartTime(val) {
      let time = val;
      let hour = Number(time.split(":")[0]);
      let min = Number(time.split(":")[1]);
      let s = Number(hour * 3600) + Number(min * 60);
      let startTime;
      let day = moment().startOf("day").unix();
      startTime = s + day + 1800;
      this.agendaForm.end_time = secondFormat(startTime, "T");
    },
  },
};
</script>
<style scoped lang="less">
.time {
  .el-date-editor {
    width: 50%;
  }
  /deep/ .el-form-item__content {
    display: flex;
  }
}
.labelSlot::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

.mt {
  margin-top: 15px;
}
.bt {
  margin-bottom: 10px;
}
.notopen {
  text-indent: 2em !important;
}
/deep/.el-dialog__header {
  line-height: 30px;
}
/deep/.el-dialog__body {
  padding: 20px 30px 0 20px;
}
</style>
